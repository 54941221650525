import { usePage } from '@inertiajs/vue3';
import { computed, ComputedRef, toRaw, toRef } from 'vue';

type GlobalProps = {
    isLocal: boolean;
    auth: {
        user: any;
    }
}

function useGlobalPropsUnauthenticated(): ComputedRef<GlobalProps> {
    const page = usePage<GlobalProps>();

    return computed(() => {
        const props: any = {};

        Object.keys(page.props).forEach(propName => {
            props[propName] = page.props[propName];
        });

        return props;
    })
}

export function useGlobalProps() {
    return {
        pageProps: useGlobalPropsUnauthenticated()
    } as const;
}
