import { createI18n } from 'vue-i18n';
import enLang from '../../../lang/en.json';
import esLang from '../../../lang/es.json';
import deLang from '../../../lang/de.json';

const i18n = createI18n({
    legacy: false,
    locale: document.querySelector('html')?.getAttribute('lang') || 'en',
    fallbackLocale: 'en',
    messages: {
        'en': enLang,
        'es': esLang,
        'de': deLang,
    },
});

export function UseTranslations() {
    return {
        i18n,
        t: i18n.global.t,
    }
}
