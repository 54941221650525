<script setup lang="ts">
import KeyboardArrowDownIcon from '@/Components/Icons/KeyboardArrowDownIcon.vue';
import BaseModal from '@/Components/Panel/BaseModal.vue';
import { computed, ref } from 'vue';
import FlagUK from '@/Components/Icons/Flags/FlagUK.vue';
import FlagES from '@/Components/Icons/Flags/FlagES.vue';
import FlagDE from '@/Components/Icons/Flags/FlagDE.vue';
import { router } from '@inertiajs/vue3';

const isOpen = ref(false);

const currentLocale = document.querySelector('html')?.getAttribute('lang') || 'en';

function switchLanguage(locale: string) {
    router.post(route('candidate.locale', { locale }), {}, {
        onFinish: () => {
            window.location.reload();
        }
    });
}

const localeName = computed(() => {
    if (currentLocale === 'en') return 'English';
    if (currentLocale === 'es') return 'Spanish';
    if (currentLocale === 'de') return 'German';
});
</script>

<template>
    <div @click="isOpen = true" class="flex px-3 py-2 rounded-md gap-2 items-center justify-start border border-gray-200 bg-white cursor-pointer hover:bg-brand-50 hover:border-brand-600 group">
        <FlagUK v-if="currentLocale === 'en'" class="w-8" />
        <FlagES v-if="currentLocale === 'es'" class="w-8" />
        <FlagDE v-if="currentLocale === 'de'" class="w-8" />

        <div class="flex gap-0.5 items-center">
            <span class="text-black group-hover:text-brand-700">{{ localeName }}</span>
            <KeyboardArrowDownIcon class="size-5 text-gray-700 group-hover:text-brand-700" />
        </div>
    </div>

    <BaseModal
        title="Switch your language"
        :is-open="isOpen"
        @close="isOpen = false"
    >
        <div class="flex flex-col gap-2">
            <div @click="switchLanguage('en')" class="flex gap-2 items-center justify-start cursor-pointer hover:bg-brand-50 p-2 -mx-2 rounded group">
                <FlagUK class="w-8" />
                <span class="text-black group-hover:text-brand-700">English</span>
            </div>

            <div @click="switchLanguage('es')" class="flex gap-2 items-center justify-start cursor-pointer hover:bg-brand-50 p-2 -mx-2 rounded group">
                <FlagES class="w-8" />
                <span class="text-black group-hover:text-brand-700">Español</span>
            </div>

            <div @click="switchLanguage('de')" class="flex gap-2 items-center justify-start cursor-pointer hover:bg-brand-50 p-2 -mx-2 rounded group">
                <FlagDE class="w-8" />
                <span class="text-black group-hover:text-brand-700">Deutsch</span>
            </div>
        </div>
    </BaseModal>
</template>

