import '../css/app.css';
import './bootstrap';

import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { App, createApp, DefineComponent, h } from 'vue';
import { ZiggyVue } from '../../vendor/tightenco/ziggy';
import * as Sentry from "@sentry/vue";
import { UseTranslations } from '@/Compositions/UseTranslations';

const appName = import.meta.env.VITE_APP_NAME;
const { i18n } = UseTranslations();

createInertiaApp({
    title: (title) => title ? `${title} - ${appName}` : appName,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob<DefineComponent>('./Pages/**/*.vue')),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });
        installSentry(app);
        app.use(plugin);
        app.use(ZiggyVue);
        app.use(i18n);
        app.mount(el);
    },
    progress: false,
});

function installSentry(app: App<Element>) {
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [],
        tracesSampleRate: 1.0,
    });
}
