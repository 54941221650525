<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';

export type ButtonType = 'primary' | 'secondary' | 'danger'
export type ButtonSize = 'sm' | 'md'

const props = withDefaults(defineProps<{
    disabled?: boolean,
    ghost?: boolean,
    label?: null | string,
    loading?: boolean,
    size?: ButtonSize
    type?: ButtonType,
    href?: string | null,
}>(), {
    type: 'primary',
    disabled: false,
    ghost: false,
    label: null,
    loading: false,
    size: 'md',
    href: null,
});

const slots = defineSlots<{
    icon: any,
}>();

const buttonType = computed<ButtonType>(() => {
    return props.type;
});

const buttonSize = computed<ButtonSize>(() => props.size === undefined ? 'md' : props.size);

const content = computed<'only-text'|'only-icon'|'text-and-icon'>(() => {
    if (slots.icon && props.label) return 'text-and-icon';
    if (props.loading) return 'text-and-icon';
    if (slots.icon) return 'only-icon';
    return 'only-text';
});

const classes = computed(() => `${buttonType.value} ${buttonSize.value} ${content.value} ${props.ghost ? 'ghost' : 'normal'} ${props.loading ? 'loading' : ''} ${props.disabled ? 'disabled' : ''}`);

const $iconSpan = ref();
const customIconWidth = ref<null|string>(null);
const customIconHeight = ref<null|string>(null);

onMounted(() => {
    const classString: string = $iconSpan.value?.querySelector('svg').getAttribute('class') ?? '';
    customIconWidth.value = classString.match(/w-\w+/g)?.[0] ?? null;
    customIconHeight.value = classString.match(/h-\w+/g)?.[0] ?? null;
});
</script>

<template>
    <component
        :is="props.href ? 'a' : 'button'"
        :class="`relative shrink-0 ${classes}`"
        style="border-style: inherit;"
        :disabled="disabled || loading"
        type="button"
        :href="props.href"
    >
        <span
            v-if="! loading && $slots.icon"
            ref="$iconSpan"
            class="`${customIconWidth} ${customIconHeight}`"
            :class="{
                'w-5 h-5': (customIconWidth === null && customIconHeight == null) && buttonSize === 'md',
                'w-4 h-4': (customIconWidth === null && customIconHeight == null) && buttonSize === 'sm',
            }"
        >
          <slot
              name="icon"
          />
        </span>
        <span
            v-if="loading"
            class="loader"
        ></span>
        <span
            :class="{
                'invisible': loading,
            }"
            v-if="label !== null"
            v-text="label"
        />
    </component>
</template>

<style lang="scss" scoped>
.normal {
    @apply inline-flex justify-center items-center border space-x-2 select-none;
}

.ghost {
    @apply inline-flex justify-center items-center border space-x-2 select-none;
}

.md {
    @apply rounded-md px-4 py-2 text-sm font-medium leading-5;

    .loader {
        @apply w-5 h-5;
        border: 2px solid #FFF;
        border-bottom-color: transparent;
        border-radius: 50%;
    }
}

.sm {
    @apply rounded px-2.5 py-1.5 text-xs font-medium leading-normal;

    .loader {
        @apply w-[1.125rem] h-[1.125rem];
    }
}

.normal.primary {
    @apply border-transparent bg-blue-700 text-white shadow-sm hover:bg-blue-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2;
}

.normal.secondary {
    @apply border-gray-300 bg-white text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2;
}

.normal.danger {
    @apply border-transparent bg-red-600 text-white shadow-sm hover:bg-red-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2;
}

.ghost.primary {
    @apply border-transparent text-blue-600 hover:text-blue-700 hover:bg-gray-100 focus:outline-none focus-visible:bg-white focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2
}

.ghost.secondary {
    @apply border-transparent text-gray-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus-visible:bg-white focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2
}

.ghost.danger {
    @apply border-transparent text-red-600 hover:text-red-700 hover:bg-gray-100 focus:outline-none focus-visible:bg-white focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.loading {
    pointer-events: none;
    opacity: 0.5;
}

.normal.primary .loader {
    border: 2px solid #FFF;
    border-bottom-color: transparent;
}

.ghost.primary .loader {
    border: 2px solid rgb(79 70 229);
    border-bottom-color: transparent;
}

.secondary .loader {
    border: 2px solid rgb(55 65 81);
    border-bottom-color: transparent;
}

.normal.danger .loader {
    border: 2px solid #FFF;
    border-bottom-color: transparent;
}

.ghost.danger .loader {
    border: 2px solid rgb(220 38 38);
    border-bottom-color: transparent;
}

.loader {
    position: absolute;
    border-radius: 50%;
    display: block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
